const config = {
	host: '//www.aqhyb.com', //接口域名
	imgRoot: 'https://huiyoubangoss.oss-cn-hangzhou.aliyuncs.com', //图片前缀
	md5: {
		master: 'uiw2kis881usjeh',
		service: 'uiw2kis8ki9sjeh',
		manufacturer: 'uiw2kis82husjeh'
	}
};
if (process.env.NODE_ENV === 'development') {
	//开发环境
	config.host = '/dev-api'
	// config.host = 'https://www.aqhyb.com'
} else if (process.env.NODE_ENV === 'production') {
	//生产环境
	// config.host = 'http://a.hnrj.hnvtcloud.cn:8301'
}
export default config

// http://hyb1.git.hnvtcloud.cn 本地
// https://hyb.git.hnvtcloud.cn 本地
// https://huiyoubangapi.git.hnvtcloud.cn 线上测试服
// http://hyb.hfrjkf.cn 线上测试服