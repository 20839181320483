<template>
	<div class="head" id="head">
		<div class="head-content-wrap L">
			<div class="head-content M">
				<div class="hc-logo pointer" :style="`background-image: url(${imgUrl(pc.logo)})`" @click="toIndex">
				</div>
				<div class="hc-nav">
					<div class="hcn-item" :class="{active: $route.name == 'index' || $route.name == 'orderCreate'}"
						@click="toIndex">
						<div class="hcni-text">用户首页</div>
						<div class="hcni-line"></div>
					</div>
					<div class="hcn-item" :class="{
							active: $route.name == 'order' ||
							$route.name == 'product' ||
							$route.name == 'wanGongAlbum' ||
							$route.name == 'afterSale' ||
							$route.name == 'zhiShiKu'}" @click="toOrder">
						<div class="hcni-text">订单管理</div>
						<div class="hcni-line"></div>
					</div>
					<div class="hcn-item"
						:class="{active: $route.name == 'account' || $route.name == 'accountRecharge'}"
						@click="toAccount">
						<div class="hcni-text">我的钱包</div>
						<div class="hcni-line"></div>
					</div>
					<div class="hcn-item"
						v-if="hasPermi('app:user:info') || hasPermi('app:user:safe') || !isSubAccount"
						:class="{active: $route.name == 'zhangHaoInfo' || $route.name == 'zhangHaoSafety' || $route.name == 'zhangHao'}"
						@click="toZhangHaoInfo">
						<div class="hcni-text">账户信息</div>
						<div class="hcni-line"></div>
					</div>
				</div>
				<div class="hc-right">
					<div class="hcr-xiadan-btn btn-fill"
						@click="hasPermi('app:order:add', {toast: true}) && toOrderCreate()">一键下单</div>
					<div class="hcr-exit-btn pointer" v-if="isLogin" @click="exit">退出</div>
					<template v-else>
						<div class="hcr-register-btn btn-fill" @click="toLogin(2)">注册</div>
						<div class="hcr-login-btn btn-fill" @click="toLogin(1)">登录</div>
					</template>
					<template v-if="NODE_ENV === 'development'">
						<el-popover placement="top-start" width="" trigger="hover">
							<div class="feiyongmingxi" >
								<div class="fy-item btn-fill"
									@click="changeAccount(accountListitem)"
									v-for="(accountListitem,accountListindex) in accountList"
									:key="`accountList-${accountListindex}`">
									<div class="fyi-label btn-fill">{{ accountListitem.name }}</div>
								</div>
							</div>
							<div class="hcr-login-btn btn-fill" slot="reference">账号</div>
						</el-popover>
					</template>
				</div>
			</div>
		</div>
		<div class="head-content-placeholder L"></div>
		<!-- 提示 -->
		<PopTip ref="PopTip" :show.sync="showPopTip"></PopTip>
	</div>
</template>

<script>
	import {
		Quit
	} from '../common/api.js'

	export default {
		name: 'Head',
		props: {
			msg: String
		},
		data() {
			return {
				showPopTip: false,
				NODE_ENV: process.env.NODE_ENV,
				accountList: [{
					name: '王虹 15155518710 / 123456',
					token: '749735ac4138407b94745efcc6c6cdd5'
				}, {
					name: '江岚',
					token: '21d30894c08f4784aa394265af702b6e'
				}, {
					name: '朱工 13185747753/868868',
					token: '4f6d8f8e4df94ea2bda7b621b75a223c'
				}, ]

			}
		},
		methods: {
			changeAccount(accountListitem) {
				console.log(111);
				this.$store.dispatch('updateToken', accountListitem.token).then(res => {//本地1-江岚
					this.toIndex()
					setTimeout(() => {
						this.toast({
							message: `已切换至${accountListitem.name}`,
							type: 'success'
						})
					},1000)
				})
			
			},
			toIndex() {
				this.$router.push({
					name: 'index'
				})
			},
			toOrder() {
				if (this.hasPermi('app:order:list')) {
					this.$router.push({
						name: 'order'
					})
				} else {
					this.$router.push({
						name: 'product'
					})
				}
			},
			toAccount() {
				if (this.hasPermi('app:account:list')) {
					this.$router.push({
						name: 'account'
					})
				} else {
					this.$router.push({
						name: 'accountRecharge'
					})
				}
			},
			toZhangHaoInfo() {
				if (this.hasPermi('app:user:info')) {
					this.$router.push({
						name: 'zhangHaoInfo'
					})
				} else if (this.hasPermi('app:user:safe')) {
					this.$router.push({
						name: 'zhangHaoSafety'
					})
				} else if (!this.isSubAccount) {
					this.$router.push({
						name: 'zhangHao'
					})
				}
			},
			toOrderCreate() {
				this.$router.push({
					name: 'orderCreate'
				})
			},
			toLogin(type) {
				this.$router.push({
					name: 'login',
					query: {
						type: type
					}
				})
			},
			exit() {
				this.$refs.PopTip.open({
					content: '确定要退出登录吗?',
					confirm: () => {
						Quit().then(res => {
							this.$store.dispatch('updateToken')
						})
					}
				})

			}

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.feiyongmingxi {
		.fy-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 32px;
	
			.fyi-label {
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}
	
			.fyi-value {
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				color: #333333;
			}
		}
	}
	.head {
		width: 100%;
		
		.head-content-placeholder {
			height: 95px;
		}

		.head-content-wrap {
			z-index: 999;
			background-color: #FFFFFF;
			position: fixed;
			top: 0;

			.head-content {
				display: flex;
				align-items: center;
				height: 95px;

				.hc-logo {
					width: 148px;
					height: 52.46px;
					// background-image: url('../assets/012.png');
					background-size: 100% 100%;
				}

				.hc-nav {
					margin: 0 auto;
					display: flex;

					.hcn-item {
						margin: 0 25px;
						height: 50px;
						display: flex;
						flex-direction: column;
						align-items: center;
						cursor: pointer;

						.hcni-text {
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 36px;
						}

						.hcni-line {
							display: none;
							width: 44px;
							height: 4px;
							background: #3571DA;
							border-radius: 8px;
						}

						&.active {
							.hcni-line {
								display: block;
							}
						}
					}
				}

				.hc-right {
					display: flex;
					align-items: center;

					.hcr-xiadan-btn {
						width: 92px;
						height: 36px;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 36px;
						text-align: center;
						background-color: #3571DA;
						border-radius: 8px;
					}

					.hcr-exit-btn,
					.hcr-register-btn,
					.hcr-login-btn {
						margin-left: 40px;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571DA;
					}
				}
			}
		}
	}
</style>